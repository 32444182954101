import React from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { clearSession, getRedirectUrl, setRedirectUrl, getUserInfo} from './utilCommon';
import { get } from 'lodash';
import { setMessage } from '../redux/shared/actions';
import store from '../store';
import { removeAppCookies, setCookie } from '../helpers/manageCookies';
import { cookieKeysEnum } from '../models/common.models';
import { clearRedis } from 'services/appServices';

// Flag For child application.
export const IS_CHILD_APP = false;
export let PROTACTED_HOME_PAGE = '/MyASTM';


// Callback method OKAT Auth handler.
export const authHandler = async (authService) => {
    const hashValue = window.location.hash;
    let defaultUrl = getRedirectUrl();
    // Check hash available in the URL.
    if (hashValue) {
        let sessionToken = window.location.hash.split('#')[1];
        window.sessionStorage.setItem('referrerPath', defaultUrl ? defaultUrl : PROTACTED_HOME_PAGE);
        OIDC.token.getWithRedirect({
            scopes: OIDC.options.scopes,
            sessionToken
        });
    }
    else {
        const isSessionExist = await authService.session.exists();
        if (isSessionExist) {
            let token = await getIdToken(authService);
            // TODO : check if token exist.
            if (!token) {
                authService.token.getWithRedirect({
                    scopes: OIDC.options.scopes
                });
            }
        } else {
            handleLogoutRedirect();
        }
    }
}

let isHandleLogoutRedirectCalled = false;
export const handleLogoutRedirect = () => {
    if (isHandleLogoutRedirectCalled) {
        return;
    }
    const redirectURL = sessionStorage.getItem('redirect-url');
    window.localStorage.setItem('isMemberStillLoginInAnotherTab', false);
    clearRedis(() => {
        isHandleLogoutRedirectCalled = true;
        clearSession();
        removeAppCookies();
        if (IS_CHILD_APP === true) {
            window.location.assign(process.env.REACT_APP_LOGOUT_URI);
        } else {
            if (redirectURL) {
                window.sessionStorage.removeItem('redirect-url');
                const genratedRedirectURL = process.env.REACT_APP_LOGIN_PAGE_REQUEST + window.btoa(window.location.origin + redirectURL);
                window.location.assign(genratedRedirectURL);
            } else {
                let loginUrl = process.env.REACT_APP_LOGIN_PAGE_REQUEST + window.btoa(window.location.origin);
                // TODO : Set value in LS to track
                window.location.assign(loginUrl);
            }
        }
    });
}

export const verifySessionAndMaintainRedirectOnExternalApp = async () => {
    // TODO: hashValue should be updated at time of implementing identity delegation
    const hashValue = window.location.hash;
    if (!hashValue) {
        const isSessionExist = await OIDC.session.exists();
        if (!isSessionExist) {
            handleLogoutRedirect();
        }
    }
}

// Logout Handler
export const logoutHandler = async () => {
    try {
        OIDC.signOut('/');
        removeAppCookies(false);
    } catch (error) {
        console.error(error);
    }
}


// TODO : Login handler.
export const loginHandler = async () => {
    authHandler(OIDC);
}

// TODO : Check ME call on Routes Change
export const verifyMECallForSession = async () => {
    const userInfo = getUserInfo();
    if (userInfo && get(OIDC, 'session')) {
        OIDC.session.get().then(async sessionInfo => {
            const tokenInfo = await getAuthHeaderToken();
            // Clear Cookies if cross tab along with [expired to new session] will be apply.
            // WEB-2949, GFN-2845- Condition order changed to monitor the twice logging issue. First check the sessionInfo then 
            // userInfo email
            if (!sessionInfo) {
                // Forcefully remove storages if no session exists.
                store.dispatch(setMessage(true, '401'));
                clearSession();
                removeAppCookies();
                loginHandler();
            } else if (userInfo.email && sessionInfo.login && userInfo.email !== sessionInfo.login) {
                clearSession();
                removeAppCookies();
                loginHandler();
            } else if (sessionInfo && !tokenInfo) {
                // Remove okta garbage storage if already exists.
                // removeSession('okta-cache-storage');
                // removeSession('okta-token-storage');
                window.sessionStorage.removeItem('okta-cache-storage');
                window.sessionStorage.removeItem('okta-token-storage');
                window.localStorage.removeItem('okta-token-storage');
                loginHandler();
            } else {
                setCookie(cookieKeysEnum["IS-OKTA-VERIFIED"], true, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
            }
        })
    }
}

//TODO : set auth token header.
export const getAuthHeaderToken = async () => {
    const tokenInfo = await OIDC.tokenManager.get('accessToken');
    // To check token is valid.
    if (tokenInfo) {
        if (OIDC.tokenManager.hasExpired(tokenInfo)) {
            const renewAccessToken = await OIDC.token.renew(tokenInfo);
            return renewAccessToken ? renewAccessToken.accessToken : null;
        } else {
            return tokenInfo.accessToken;
        }
    } else {
        return null;
    }
};

// TODO : get ID token from okta auth
export const getIdToken = (oktaAuth) => {
    return oktaAuth.tokenManager.get('id_token');
};

export const storageProvider = {
    getItem: function (key) {
        // custom get
        const token = !sessionStorage.getItem('okta-token-storage') ? localStorage.getItem('okta-token-storage') : sessionStorage.getItem('okta-token-storage');
        return token;
    },
    setItem: function (key, val) {
        // custom set     
        sessionStorage.setItem('okta-token-storage', val)
        localStorage.setItem('okta-token-storage', val)
        // myMemoryStore[key] = val;
    },
    // optional
    removeItem: function (key) {
        sessionStorage.clear();
    }
}
// Setup Configuration for OktaAuth. 
export const OIDC = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true, // [true] provides a modern solution for protecting SPAs. 
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URI,
    disableHttpsCheck: window.location.hostname !== 'localhost' ? true : false, // [True] if working on https environment.
    tokenManager: {
        expireEarlySeconds: 60,
        autoRenew: true, // autorenew [true] auto update access token
        secure: window.location.hostname !== 'localhost' ? true : false, // This option is only relevant if storage is set to cookie and false option is only for testing
        storage: storageProvider  // localStorage/sessionStorage/cookie,
    }
});

// TODO : Render security component from common place.
export const BrowserRoutesProvider = ({ OIDC_CONFIG, ...props }) => {
    if (window.location.href.indexOf("#IdentityDelegationRefreshToken") !== -1) {
        clearSession();
        setRedirectUrl('/MyASTM');
        window.location.replace(window.location.origin);
    }
    return (
        <Router basename='/'>
            <Security oktaAuth={OIDC_CONFIG} onAuthRequired={authHandler}>
                <Route path="/auth" component={LoginCallback} />
                {props.children}
            </Security>
        </Router>
    );
}

export default {
    OIDC,
    BrowserRoutesProvider,
    authHandler,
    getAuthHeaderToken,
    loginHandler,
    verifyMECallForSession
}